import '@/assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import PrimeVue from 'primevue/config'
import Lara from '@primevue/themes/lara'
import ToastService from 'primevue/toastservice'
import 'primeicons/primeicons.css'
// import * as Sentry from '@sentry/vue'

const pinia = createPinia()
const app = createApp(App)

// Sentry.init({
//   app,
//   dsn: 'https://dff7b6dd9576e402f4adcec48fe62cc2@o4507884051496960.ingest.de.sentry.io/4507884053659728',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', 'http://bot.senseai.pro/'],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

app.use(router)
app.use(PrimeVue, {
  theme: {
    preset: Lara
  }
})
app.use(pinia)
app.use(ToastService)

const store = useAuthStore()

router.beforeEach((to, from, next) => {
  if (to.query.redirect && store.token) {
    if (to.query.redirect.includes('chat')) {
      next({
        name: 'chat',
        path: to.query.redirect,
        params: { id: to.query.redirect.split('/')[1] }
      })
    } else {
      next({ name: to.query.redirect, path: to.query.redirect })
    }
    return
  }
  if (to.name === 'login') {
    if (store.token) {
      next({ name: 'list' })
      return
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.token) {
      next({ name: 'login' })
    } else {
      next()
    }
    next()
  } else {
    next()
  }
})

app.mount('#app')
