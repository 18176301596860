<script setup lang="ts">
import { RouterView, RouterLink } from 'vue-router'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar'
import Toast from 'primevue/toast'
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
const store = useAuthStore()

const items = ref([
  {
    label: 'Список пользователей',
    route: '/list'
  },
  {
    label: 'Полотна',
    route: '/templatesView'
  },
  {
    label: 'Помощь',
    route: '/helpView'
  },
  {
    label: 'Рассылка',
    route: '/mailingView'
  },
  {
    label: 'Задания',
    route: '/tasksView'
  }
])

function logout() {
  localStorage.clear()
  window.location.reload()
}
</script>

<template>
  <nav>
    <Toast />
    <Menubar :model="items">
      <template #item="{ item, props }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a v-ripple :href="href" v-bind="props.action" @click="navigate">
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </template>
      <template #end>
        <div class="flex items-center gap-2">
          <Button v-if="store.token" @click="logout">Выход</Button>
        </div>
      </template>
    </Menubar>
    <!-- <RouterLink to="/list"><Button label="Список пользователей" icon="pi pi-home" /></RouterLink>
      <RouterLink to="/templatesView"><Button label="Шаблоны" icon="pi pi-home" /></RouterLink> -->
  </nav>
  <RouterView />
</template>

<style scoped></style>
