<script setup lang="ts">
import { useRoute, RouterLink } from 'vue-router'
import { reactive, onMounted, ref } from 'vue'
import format from 'date-format'
import Checkbox from 'primevue/checkbox'
import ToggleSwitch from 'primevue/toggleswitch'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Dialog from 'primevue/dialog'
import LearningStats from '@/components/LearningStats.vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { useAuthStore } from '@/stores/auth'
import { useStateStore } from '@/stores/state'
import Popover from 'primevue/popover'
import { useToast } from 'primevue/usetoast'
import knockSfx from '@/assets/knock.mp3'
import ContextMenu from 'primevue/contextmenu'
// import VirtualScroller from 'primevue/virtualscroller'
import { type User, type Recommendation, type DataLists } from '@/types'
import { axiosInstance } from '@/http'

const toast = useToast()

function knockKnock() {
  const audio = new Audio(knockSfx)

  function play() {
    try {
      audio.play()
    } catch (err) {
      console.log('will not play')
    }
  }

  return { play }
}
// ...

const route = useRoute()
const store = useAuthStore()
const state = useStateStore()

const dialogs: DataLists = reactive({
  list: [],
  recommendations: []
})
const value = ref('')
const commentValue = ref('')
const moderatedValue = ref('')
const visible = ref(false)
const currentCommentList = reactive({
  user_id: 0,
  id: 0,
  recommendation: '',
  recommendation_moderated: '',
  published: false,
  comments: []
})

const op = ref()
const blockedControls = ref(false)
const whoIsLooking = ref()
const replyTo = ref('')
const editMessage = ref('')
const selectedMessage = ref()

state.$subscribe((mutation, state) => {
  if (state.logs) {
    const lastMessage = state.logs[state.logs.length - 1]
    if (lastMessage) {
      if (lastMessage && `${lastMessage.chatId}` == route.params.id) {
        setTimeout(loadDialogs, 300)
      }
    }
  }
  checkLock(state)
})

checkLock(state)

function checkLock(state: any) {
  const lockedChatPresent = state.lockedChats.find((singleLock: { chatId: string }) => {
    return singleLock.chatId === `${route.params.id}`
  })
  if (lockedChatPresent) {
    whoIsLooking.value = lockedChatPresent
  } else {
    whoIsLooking.value = {}
  }
  if (lockedChatPresent && lockedChatPresent.user.username != store.user.username) {
    blockedControls.value = true
  } else {
    blockedControls.value = false
  }
}

const user = ref<User>({
  user_id: '',
  name: '',
  additional: '',
  tilda_user_id: null,
  tilda_user_name: '',
  badge_user_id: '',
  belt_id: '',
  shop_name: '',
  learn_type: '',
  learn_stage: '',
  belt: ''
})
function loadDialogs() {
  const knock = knockKnock()
  axiosInstance
    .post(
      '/chat/' + route.params.id,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      dialogs.list = response.data.sort(
        (a: { created_at: string | number | Date }, b: { created_at: string | number | Date }) => {
          const dateA = new Date(a.created_at).getTime()
          const dateB = new Date(b.created_at).getTime()
          return dateA - dateB
        }
      )

      setTimeout(scrollToBottom, 500)
      try {
        knock.play()
      } catch (err) {
        console.log('Cannnot play until interacted')
      }
    })
}

function loadUser() {
  axiosInstance
    .get('/users/' + route.params.id, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      user.value = response.data
    })
}

function loadRecommendations() {
  axiosInstance
    .get('/recommendation/' + route.params.id, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      dialogs.recommendations = response.data
    })
}

function appendToChat() {
  if (!value.value) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Не буду отправлять пустое сообщение',
      life: 3000
    })
    return false
  }

  if (editMessage.value !== '') {
    axiosInstance
      .put(
        '/message/',
        {
          to: route.params.id,
          message: value.value,
          replyTo: editMessage.value,
          dialogId: selectedMessage.value.id
        },
        {
          headers: {
            Authorization: 'Bearer ' + store.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dialogs.list = response.data.sort(
          (
            a: { created_at: string | number | Date },
            b: { created_at: string | number | Date }
          ) => {
            const dateA = new Date(a.created_at).getTime()
            const dateB = new Date(b.created_at).getTime()
            return dateA - dateB
          }
        )
        selectedMessage.value = {}
        replyTo.value = ''
        editMessage.value = ''
        value.value = ''
        setTimeout(scrollToBottom, 500)
      })
  } else {
    axiosInstance
      .post(
        '/message/',
        { to: route.params.id, message: value.value, replyTo: replyTo.value },
        {
          headers: {
            Authorization: 'Bearer ' + store.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dialogs.list = response.data.sort(
          (
            a: { created_at: string | number | Date },
            b: { created_at: string | number | Date }
          ) => {
            const dateA = new Date(a.created_at).getTime()
            const dateB = new Date(b.created_at).getTime()
            return dateA - dateB
          }
        )
        replyTo.value = ''
        value.value = ''
        selectedMessage.value = {}
        setTimeout(scrollToBottom, 500)
      })
  }
}

function removeMessage(dialogId: string, messageId: string) {
  axiosInstance
    .post(
      '/message/delete',
      {
        to: route.params.id,
        messageId: messageId,
        dialogId: dialogId
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      dialogs.list = response.data.sort(
        (a: { created_at: string | number | Date }, b: { created_at: string | number | Date }) => {
          const dateA = new Date(a.created_at).getTime()
          const dateB = new Date(b.created_at).getTime()
          return dateA - dateB
        }
      )
      selectedMessage.value = {}
      replyTo.value = ''
      editMessage.value = ''
      value.value = ''
      setTimeout(scrollToBottom, 500)
    })
}

function addComment() {
  axiosInstance
    .post(
      '/comment/',
      {
        entityId: currentCommentList.id,
        entityType: 'recommendation',
        author: store.user.username,
        body: commentValue.value
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(() => {
      commentValue.value = ''
      loadComments()
    })
}

async function loadComments() {
  axiosInstance
    .get(`/comments/recommendation/${currentCommentList.id}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      currentCommentList.comments = response.data
    })
}

async function lockChat() {
  axiosInstance
    .post(
      `/chat/${route.params.id}/lock`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      checkLock(state)
    })
}

onMounted(() => {
  loadDialogs()
  loadRecommendations()
  loadUser()
  lockChat()
})

function openDialog(recomm: Recommendation) {
  currentCommentList.user_id = recomm.user_id
  currentCommentList.id = recomm.id
  currentCommentList.recommendation = recomm.recommendation
  currentCommentList.recommendation_moderated = recomm.recommendation_moderated
  moderatedValue.value = recomm.recommendation_moderated
  currentCommentList.published = recomm.published
  visible.value = true
  loadComments()
}

function updateRecommendation() {
  axiosInstance
    .post(
      '/recommendations/update',
      {
        id: currentCommentList.id,
        recommendation_moderated: moderatedValue.value,
        published: currentCommentList.published
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      toast.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Рекомендация обновлена',
        life: 3000
      })
    })
}

function truncateText(text: string) {
  if (text.length > 350) {
    return text.substring(0, 350) + '...'
  }
  return text
}

function scrollToBottom() {
  const container = document.getElementById('scrollContainer')
  if (container !== null) {
    container.scrollTop = container?.scrollHeight
  }
}

function onSelectEmoji(emoji: { i: string }) {
  value.value = value.value + ' ' + emoji.i
}

const toggleEmojiSelector = (event: any) => {
  op.value.toggle(event)
}

const turnIntoCommand = function (settings: string) {
  const setting = settings.split('|')
  const commandBreak = setting[1].split(':')
  return {
    title: setting[0],
    command: commandBreak[0],
    commandArg: commandBreak[1]
  }
}

async function finishExam(setting: any) {
  if (setting.command === 'finishLesson') {
    axiosInstance
      .post(
        `/excerciseToggle`,
        {
          toggle: true,
          userId: route.params.id,
          lessonId: parseInt(setting.commandArg),
          fromAdmin: true
        },
        {
          headers: {
            Authorization: 'Bearer ' + store.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Статус урока обновлен',
          life: 3000
        })
        loadDialogs()
      })
  }
  if (setting.command === 'failLesson') {
    axiosInstance
      .post(
        `/excerciseToggle`,
        {
          toggle: false,
          userId: route.params.id,
          lessonId: parseInt(setting.commandArg),
          fromAdmin: true
        },
        {
          headers: {
            Authorization: 'Bearer ' + store.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Статус урока обновлен',
          life: 3000
        })
        loadDialogs()
      })
  }
}

function isThisVoice(text: string) {
  return text.includes('voice:')
}

function appendReplyId(message: any) {
  replyTo.value = message.message_id
  selectedMessage.value = {}
}

function getQuote(replied_to_id: string, messagesList: any[]) {
  if (!replied_to_id || replied_to_id === '0') {
    return ''
  }
  const foundMessage = messagesList.find(
    (singleFoundMessage) => singleFoundMessage.message_id === replied_to_id
  )
  if (foundMessage) {
    return foundMessage.text
  }
  return ''
}

const menu = ref()
const items = ref([
  {
    label: 'Reply',
    icon: 'pi pi-reply',
    command: () => {
      appendReplyId(selectedMessage.value)
    }
  },
  {
    label: 'Edit',
    icon: 'pi pi-file-edit',
    command: () => {
      if (selectedMessage.value.role === 'user') {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Не могу отредактировать сообщение пользователя',
          life: 3000
        })
        editMessage.value = ''
        value.value = ''
      } else {
        editMessage.value = selectedMessage.value.message_id
        value.value = selectedMessage.value.text
      }
    }
  },
  {
    label: 'Remove',
    icon: 'pi pi-trash',
    command: () => {
      if (selectedMessage.value.role === 'user') {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Не могу удалить сообщение пользователя',
          life: 3000
        })
        selectedMessage.value = {}
        editMessage.value = ''
        value.value = ''
      } else {
        removeMessage(selectedMessage.value.id, selectedMessage.value.message_id)
      }
    }
  }
])

const onMessageRightClick = (event: any, message: any) => {
  menu.value.show(event)
  selectedMessage.value = message
}
</script>

<template>
  <main>
    <h3>{{ user.name }} / {{ user.belt }}</h3>
    <h4>Сейчас общается: {{ whoIsLooking?.user?.username }}</h4>
    <Dialog v-model:visible="visible" modal header="Комментарии" :style="{ width: '85rem' }">
      <div class="max-h-[40vh] overflow-y-auto">
        <span style="white-space: pre">{{ currentCommentList.recommendation }}</span>
      </div>
      <Splitter v-if="false" style="height: 300px" class="mb-8" :unstyled="true">
        <SplitterPanel class="flex items-center justify-center">
          <Panel header="Оригинал">
            <p class="m-0 min-w-full">
              {{ currentCommentList.recommendation }}
            </p>
          </Panel>
        </SplitterPanel>
        <SplitterPanel class="flex items-center justify-center"
          ><Panel header="Модерация">
            <!-- <p class="publish-control">
              <ToggleSwitch disabled v-model="currentCommentList.published" /> <p>Опубликовано</p>
            </p> -->
            <p class="m-0 min-w-full">
              <Textarea
                disabled
                v-model="moderatedValue"
                rows="5"
                cols="60"
                :width="100"
                variant="filled"
              />
            </p>
            <Button
              disabled
              type="button"
              label="Сохранить"
              @click="updateRecommendation"
            ></Button> </Panel
        ></SplitterPanel>
      </Splitter>
      <br />
      <hr />
      <DataTable :value="currentCommentList.comments" paginator :rows="8">
        <Column field="created_at" sortable header="created_at" style="width: 15%"></Column>
        <Column field="author" header="author" style="width: 10%"></Column>
        <Column field="text" header="text"></Column>
      </DataTable>
      <h3>Добавить комментарий</h3>
      <Textarea v-model="commentValue" rows="5" cols="60" :width="100" variant="filled" />
      <div class="flex justify-end gap-2">
        <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
        <Button type="button" label="Save" @click="addComment"></Button>
      </div>
    </Dialog>

    <div class="h-[80vh] flex flex-col p-2">
      <ContextMenu ref="menu" :model="items" />
      <!-- <div
        id="scrollContainer"
        class="h-full overflow-y-auto space-y-2 bg-slate-700 p-5 shadow-2xl"
      >
        <VirtualScroller :items="dialogs.list" :itemSize="50">
          <template v-slot:item="{ item, options }">
            <div
              class="mx-auto text-white flex flex-row max-w-4xl"
              :id="'chatview-' + item.message_id"
              :class="{
                'items-end justify-end': item.role === 'bot',
                'items-start': item.role === 'user'
              }"
            >
              <div
                v-if="item.role === 'bot' || item.role === 'user'"
                class="max-w-2xl rounded-lg shadow-md p-2 px-3 flex flex-row"
                :class="{
                  'bg-green-500': item.role === 'bot',
                  'bg-gray-500': item.role === 'user'
                }"
                @contextmenu="onMessageRightClick($event, item)"
              >
                <div v-if="!isThisVoice(item.text)">
                  <i v-if="item.replied_to_id != '0'"
                    >> {{ getQuote(item.replied_to_id, dialogs.list) }}</i
                  >
                  <p>{{ item.text }}</p>

                  <small
                    >{{ item.role }}
                    {{ format.asString('dd-MM hh:mm', new Date(item.created_at)) }}</small
                  >
                </div>
                <div v-if="isThisVoice(item.text)">
                  <audio ref="audioPlayer" :src="item.text.replace('voice:', '')" controls></audio>
                  <small
                    >{{ item.role }}
                    {{ format.asString('dd-MM hh:mm', new Date(item.created_at)) }}</small
                  >
                </div>
              </div>
              <div v-if="item.role === 'system'" class="-mr-40 rounded-lg p-2 px-3 system-command">
                <p>
                  <Button @click="finishExam(turnIntoCommand(item.text))">{{
                    turnIntoCommand(item.text).title
                  }}</Button>
                  <small
                    >{{ item.role }}
                    {{ format.asString('dd-MM hh:mm', new Date(item.created_at)) }}</small
                  >
                </p>
              </div>
            </div>
          </template>
        </VirtualScroller>
      </div> -->
      <div
        class="h-full overflow-y-auto space-y-2 bg-slate-700 p-5 shadow-2xl"
        id="scrollContainer"
      >
        <div
          v-for="(message, index) in dialogs.list"
          :key="index"
          class="mx-auto text-white flex flex-row max-w-4xl"
          :id="'chatview-' + message.message_id"
          :class="{
            'items-end justify-end': message.role === 'bot',
            'items-start': message.role === 'user'
          }"
        >
          <div
            v-if="message.role === 'bot' || message.role === 'user'"
            class="max-w-2xl rounded-lg shadow-md p-2 px-3 flex flex-row"
            :class="{
              'bg-green-500': message.role === 'bot',
              'bg-gray-500': message.role === 'user'
            }"
            @contextmenu="onMessageRightClick($event, message)"
          >
            <div v-if="!isThisVoice(message.text)">
              <i v-if="message.replied_to_id != '0'"
                >> {{ getQuote(message.replied_to_id, dialogs.list) }}</i
              >
              <p>{{ message.text }}</p>
              <!-- <small v-if="message.role === 'user'" @click="appendReplyId(message)">ответить</small> -->

              <small
                >{{ message.role }}
                {{ format.asString('dd-MM hh:mm', new Date(message.created_at)) }}</small
              >
            </div>
            <div v-if="isThisVoice(message.text)">
              <audio ref="audioPlayer" :src="message.text.replace('voice:', '')" controls></audio>
              <small
                >{{ message.role }}
                {{ format.asString('dd-MM hh:mm', new Date(message.created_at)) }}</small
              >
            </div>
          </div>
          <div v-if="message.role === 'system'" class="-mr-40 rounded-lg p-2 px-3 system-command">
            <p>
              <Button @click="finishExam(turnIntoCommand(message.text))">{{
                turnIntoCommand(message.text).title
              }}</Button>
              <small
                >{{ message.role }}
                {{ format.asString('dd-MM hh:mm', new Date(message.created_at)) }}</small
              >
            </p>
          </div>
        </div>
        <div v-if="dialogs.list.length === 0">
          <span class="text-gray-400 ml-5">Диалог пуст</span>
        </div>
      </div>
      <button
        class="scroll-button pi pi-angle-double-down"
        style="background-color: #27272a; border: none"
        @click="scrollToBottom"
      ></button>
      <div class="flex space-x-2 p-5 content-center items-center reply-holder">
        <div class="reply-to" v-if="replyTo">
          Отвечаем на сообщение: {{ truncateText(getQuote(replyTo, dialogs.list)) }}
        </div>
        <div class="flex space-x-2 p-5 min-w-[90vw]">
          <Textarea
            v-model="value"
            placeholder="Напишите сообщение..."
            class="w-full px-2 py-1 rounded-md border-2 border-blue-300"
          />
          <div class="inline-grid grid-cols-1 gap-2">
            <Button class="px-1" icon="pi pi-envelope" label="Отправить" @click="appendToChat" />
            <Button
              label="Обновить"
              icon="pi pi-refresh"
              @click="loadDialogs"
              severity="secondary"
            />
            <Button
              type="button"
              icon="pi pi-face-smile"
              @click="toggleEmojiSelector"
              severity="secondary"
            />

            <Popover ref="op" appendTo="body" :unstyled="true">
              <EmojiPicker theme="dark" :native="true" @select="onSelectEmoji" />
            </Popover>
          </div>
        </div>
      </div>
    </div>

    <h3>Статистика обучения</h3>
    <LearningStats :userId="Number(route.params.id)" />
  </main>
</template>

<!-- <h3>Рекомендаии</h3>
<DataTable
  sortField="created_at"
  :sortOrder="-1"
  :value="dialogs.recommendations"
  paginator
  :rows="8"
>
  <Column field="created_at" sortable header="created_at">
    <template #body="slotProps">
      {{ format.asString('dd-MM hh:mm:ss', new Date(slotProps.data.created_at)) }}
    </template>
  </Column>
  <Column field="date" header="date"></Column>
  <Column field="recommendation" header="recommendation" style="width: 60%">
    <template #body="slotProps">
      {{ truncateText(slotProps.data.recommendation) }}
    </template>
  </Column>
  <Column field="filename" header="filename"></Column>
  <Column>
    <template #body="slotProps">
      <Button label="редактировать" @click="openDialog(slotProps.data)" severity="secondary" />
    </template>
  </Column>
</DataTable> -->
<style scoped>
small {
  display: block;
  font-size: 0.6em;
}
main {
  padding: 20px;
}
textarea {
  width: 100%;
}
.system-command {
  float: right;
}

.p-panel {
  width: 100%;
  height: 100%;
}

.publish-control {
  display: flex;
  padding: 10px 0;
}
.publish-control > p {
  margin-left: 10px;
}
.reply-to {
  position: absolute;
  top: 5px;
  left: 50px;
}
.reply-holder {
  position: relative;
}
</style>
