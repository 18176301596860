<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { useToast } from 'primevue/usetoast'
import { type Lesson } from '@/types'
import { axiosInstance } from '@/http'

const toast = useToast()

interface Props {
  userId: number
}

const store = useAuthStore()
const props = defineProps<Props>()
const lessonData = ref([])
const visible = ref(false)

async function loadStats(userId: number) {
  if (!userId) {
    return
  }
  axiosInstance
    .get(`/lessonStats/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      lessonData.value = response.data
    })
}

onMounted(() => {
  loadStats(props.userId)
})

function toggleLearn(data: Lesson, toggle: boolean) {
  if (data.courseId === '0') {
    visible.value = true
  }
  toggleLearnData(data, toggle)
}

function toggleLearnData(data: Lesson, toggle: boolean) {
  axiosInstance
    .post(
      '/excerciseToggle/',
      { userId: props.userId, lessonId: data.courseId, toggle, fromAdmin: true },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      if (response.status === 200) {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Статус урока обновлен',
          life: 3000
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Ошибка обновления',
          life: 3000
        })
      }
    })
}

function assignCourse(lessonId: string) {
  console.log(lessonId)
  axiosInstance
    .get(`/assignCourse/${props.userId}/${lessonId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      visible.value = false
      console.log(response)
    })
  return true
}

function cleanUp(lessonId: string) {
  console.log(lessonId)
  axiosInstance
    .post(`/cleanup/${props.userId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      console.log(response)
      window.location.reload()
    })
  return true
}

function assignExam(lessonId: string) {
  axiosInstance
    .get(`/assignExam/${props.userId}/${lessonId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      visible.value = false
      console.log(response)
    })
  return true
}

function sendtext(code: string) {
  axiosInstance
    .get(`/sendText/${props.userId}/${code}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      console.log(response)
    })
}

function determineEye(status: string) {
  if (
    status === 'in_progress' ||
    status === 'not_started' ||
    status === 'completed' ||
    status === 'complete'
  ) {
    return 'pi pi-eye'
  }

  return 'pi pi-eye-slash'
}

function determineEyeColor(status: string) {
  if (status === 'in_progress') {
    return 'font-size: 2rem; color: yellow'
  }
  if (status === 'not_started') {
    return 'font-size: 2rem; color: red'
  }
  if (status === 'completed' || status === 'complete') {
    return 'font-size: 2rem; color: #34d399'
  }

  return 'font-size: 2rem; color: #27272a'
}
</script>

<template>
  <main>
    <Dialog v-model:visible="visible" modal header="Выбрать уровень" :style="{ width: '85rem' }">
      <div>
        <Button
          type="button"
          label="Новичок"
          @click="assignCourse('1c3b4926-a10b-11ef-9710-462d2bfe4fee')"
        ></Button>
        <Button
          type="button"
          label="Опытный"
          @click="assignCourse('74ab097e-a2ab-11ef-a369-a2e57e9f528b')"
        ></Button>
      </div>
    </Dialog>
    <DataTable :value="lessonData" sortField="title" :sortOrder="1">
      <Column field="courseId" header="Назначить">
        <template #body="slotProps">
          <Button
            type="button"
            label="Назначить Курс"
            @click="assignCourse(slotProps.data.courseId)"
          ></Button>
        </template>
      </Column>
      <Column field="courseId" header="Задание">
        <template #body="slotProps">
          <Button
            type="button"
            label="Назначить Задание"
            severity="secondary"
            @click="assignExam(slotProps.data.courseId)"
          ></Button>
        </template>
      </Column>
      <Column field="title" :sortable="true" header="Название"></Column>
      <Column field="description" header="Описание"></Column>
      <Column field="viewUrl" header="Ссылка">
        <template #body="slotProps">
          <a target="_blank" :href="slotProps.data.viewUrl">Открыть</a>
        </template>
      </Column>
      <Column field="visited" header="Просмотрен">
        <template #body="slotProps">
          <i
            :title="slotProps.data.visited"
            :class="determineEye(slotProps.data.visited)"
            :style="determineEyeColor(slotProps.data.visited)"
          />
        </template>
      </Column>
      <Column field="passedTest" header="Упражнение пройдено">
        <template #body="slotProps">
          <!-- <Badge
            :value="slotProps.data.passedTest"
            :severity="slotProps.data.passedTest ? 'success' : 'danger'"
          ></Badge> -->
          <!-- <ToggleSwitch v-model="slotProps.data.passedTest" /> -->
          <Checkbox
            @change="toggleLearn(slotProps.data, slotProps.data.passedTest)"
            v-model="slotProps.data.passedTest"
            :binary="true"
          />
        </template>
      </Column>
    </DataTable>
    <Button
      type="button"
      label="Очистить статистику обучения"
      severity="danger"
      @click="cleanUp()"
    ></Button>
  </main>
</template>

<style scoped></style>
